import React from 'react';
import { Box, Button, Header } from 'grommet';
import { Menu } from 'grommet-icons';
import { ThemeSwitchButton } from '../../ThemeSwitch/ThemeSwitchButton';
import { Logout as IconLogout, UserSettings as IconUserSettings } from 'grommet-icons/icons';
import { rootStore } from '../../store/RootStore';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

type Props = {
  sidebar: boolean;
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const HeaderComponent = observer(({ sidebar, setSidebar }: Props) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    setSidebar(false);
    rootStore.AuthStore.logout().then(() => {
      navigate('/login');
    });
  };
  const handleUserSettings = () => {
    navigate('/user-settings');
  };
  return (
    <Header
      gridArea='header'
      direction='row'
      background='baseBackground'
      align='center'
      justify='between'
      pad={{ horizontal: 'medium', vertical: 'small' }}
      border={{
        color: 'borderElement',
        side: 'bottom',
        size: '1px',
        style: 'outset',
      }}
    >
      <Button onClick={() => setSidebar(!sidebar)}>
        <Menu />
      </Button>
      <Box direction='row'>
        <ThemeSwitchButton />
        <Button icon={<IconUserSettings />} onClick={handleUserSettings}></Button>
        <Button icon={<IconLogout />} onClick={handleLogout} />
      </Box>
    </Header>
  );
});
