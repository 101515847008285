import { makeAutoObservable } from 'mobx';
import { TCurrentUser } from 'models/CurrentUser';
import { getCurrentUser, getCurrentUserAuthenticators, removeCurrentUserAuthenticator } from 'api/user';
import { TAuthRequest, TAuthResponse, TAuthResponseError } from '../models/Auth';
import { postAuth, postLogout } from '../api/auth';
import { TAuthenticator } from '../models/Authenticator.tx';

// Проверка типа response
function isTAuthResponse(response: TAuthResponse | TAuthResponseError): response is TAuthResponse {
  return (response as TAuthResponse).login !== undefined;
}

export class AuthStore {
  currentUserData: TCurrentUser | null = null;
  authenticators: TAuthenticator[] = [];
  isAuthenticatorsLoading = true;
  isLoading = true;
  errorCurrentUser: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get currentUserExists() {
    return !!(this.currentUserData?.id && !this.isLoading);
  }

  login(formData: TAuthRequest) {
    return postAuth(formData).then((result) => {
      if (isTAuthResponse(result)) {
        this.currentUserData = result;
        this.setErrorCurrentUser(undefined);
      } else {
        this.setErrorCurrentUser(result.message);
      }
    });
  }

  getCurrentUserData() {
    this.setLoading(true);
    return getCurrentUser()
      .then((result) => {
        this.currentUserData = result;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  requestAuthenticators() {
    this.isAuthenticatorsLoading = true;
    return getCurrentUserAuthenticators()
      .then((result) => {
        this.authenticators = result;
      }).finally(() => {
        this.setAuthenticatorLoading(false);
      })
  }

  removeAuthenticator(authenticatorId: string | number) {
    this.isAuthenticatorsLoading = true;
    return removeCurrentUserAuthenticator(authenticatorId)
      .then(() => {
         return this.requestAuthenticators();
      }).finally(() => {
        this.setAuthenticatorLoading(false);
      })
  }

  setErrorCurrentUser(error: string | undefined) {
    this.errorCurrentUser = error;
  }

  logout() {
    this.setLoading(true);
    return postLogout()
      .then((result) => {
        if (result.isLogout) {
          this.currentUserData = null;
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setAuthenticatorLoading = (isLoading: boolean) => {
    this.isAuthenticatorsLoading = isLoading;
  };

  setCurrentUserData = (data: TCurrentUser | null) => {
    this.currentUserData = data;
  }
}
