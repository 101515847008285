import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  color: inherit;
  text-decoration: none;
  width: fit-content;
`;

export const LinkButtonEdit = styled(Link)`
  color: inherit;
  text-decoration: none;
  width: 100%;
`;
