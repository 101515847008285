import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { AllRoutesRender } from 'AllRoutesRender';
import { routes } from 'routes';
import { Loader } from 'components/Loader';
import { rootStore } from 'store/RootStore';
import { Login } from './containers/Auth/Login';

const outerRoutes = routes.filter((route) => route.isOuter).map((route) => route.path);

export const RouteSwitch: FC = observer(() => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    rootStore.AuthStore.getCurrentUserData();
  }, []);
  if (rootStore.AuthStore.isLoading) {
    return (
      <div className='main-layout'>
        <Loader fill={true} />
      </div>
    );
  }
  return <>{outerRoutes.includes(pathname) ? <Login /> : <AllRoutesRender />}</>;
});
