import React, { FC } from 'react';
import { Main } from '../components/Main/MainList';
import { Outlet } from 'react-router-dom';
import { Text, Box } from 'grommet';
import { rootStore } from '../store/RootStore';
import { Loader } from '../components/Loader';

export const LayoutForInnerRoute: FC = () => {
  if (!rootStore.AuthStore.currentUserExists) {
    return (
      <div className='main-layout'>
        <Loader fill={true} />
      </div>
    );
  }
  return (
    <Main>
      {window.location.pathname === '/' && (
        <Box margin='medium'>
          <Text>Добро пожаловать в панель администрирования!</Text>
          <Text>Для работы с пользователями перейдите в раздел Пользователи. </Text>
        </Box>
      )}
      <Outlet />
    </Main>
  );
};
