import { Accordion, AccordionPanel, Box, Select, Text } from 'grommet';
import React from 'react';
import { URLSearchParamsInit } from 'react-router-dom';
import {
  FILTER_SHOW_BY,
  INITIAL_FILTER,
  TABLE_USER_ORDER_VALUE,
  TABLE_USER_SORT_VALUE,
} from 'constants/globalConstants';
import styled from 'styled-components';
import { TableOrderValueType, TableSortValueType } from 'models/Users';

export const AccordionWrapper = styled(Accordion)`
  margin: 10px 0;
`;

export const SelectFilter = styled(Select)`
  min-width: 200px;
  max-width: 250px;
`;

export const AccordionBox = styled(Box)`
  padding: 10px;
`;

export const FilterBox = styled(Box)`
  min-width: 490px;
`;

type Props = {
  take: string;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: { replace?: boolean | undefined; state?: never } | undefined,
  ) => void;
  sortColumn: string;
  sortOrder: string;
};

const getObjectKey = (objectParam: Record<string, string>, searchValue: string): string => {
  let result = '';
  Object.keys(objectParam).forEach((key) => {
    if (objectParam[key] === searchValue) {
      result = key;
    }
  });
  return result;
};

export const AccordionComponent = ({ take, setSearchParams, sortColumn, sortOrder }: Props) => {
  const onChangeFilter = (sortColumnValue: string, sortOrderValue: string, takeValue: string) => {
    const newParams = {
      take: takeValue,
      skip: String(INITIAL_FILTER.skip),
      sort: sortColumnValue,
      order: sortOrderValue,
    };
    setSearchParams(newParams);
  };
  const sortColumnArray = Object.values(TABLE_USER_SORT_VALUE);
  const sortOrderArray = Object.values(TABLE_USER_ORDER_VALUE);
  return (
    <Box>
      <AccordionWrapper animate={true}>
        <AccordionPanel label='Параметры вывода и сортировки'>
          <AccordionBox
            background='baseBackground'
            direction='row'
            align='center'
            justify='start'
            wrap
          >
            <FilterBox direction={'row'} align='center' margin='xxsmall' justify={'between'}>
              <Text margin={{ right: 'medium', left: 'medium' }}>Сортировать по:</Text>
              <SelectFilter
                options={sortColumnArray}
                value={TABLE_USER_SORT_VALUE[sortColumn as TableSortValueType]}
                onChange={({ option }) =>
                  onChangeFilter(getObjectKey(TABLE_USER_SORT_VALUE, option), sortOrder, take)
                }
              />
            </FilterBox>
            <FilterBox direction={'row'} align='center' margin='xxsmall' justify={'between'}>
              <Text margin={{ right: 'medium', left: 'medium' }}>Вид сортировки:</Text>
              <SelectFilter
                options={sortOrderArray}
                value={TABLE_USER_ORDER_VALUE[sortOrder as TableOrderValueType]}
                onChange={({ option }) =>
                  onChangeFilter(sortColumn, getObjectKey(TABLE_USER_ORDER_VALUE, option), take)
                }
              />
            </FilterBox>
            <FilterBox direction={'row'} align='center' margin='xxsmall' justify={'between'}>
              <Text margin={{ right: 'medium', left: 'medium' }}>Показывать по:</Text>
              <SelectFilter
                options={FILTER_SHOW_BY}
                value={take}
                onChange={({ option }) => onChangeFilter(sortColumn, sortOrder, option)}
              />
            </FilterBox>
          </AccordionBox>
        </AccordionPanel>
      </AccordionWrapper>
    </Box>
  );
};
