import React, { FC } from 'react';
import { Box, Text } from 'grommet';

export const Page404: FC = () => {
  return (
    <Box fill align='center' justify='center'>
      <Text size='4xl'>404</Text>
      <Text size='large'>Not found</Text>
    </Box>
  );
};
