export const theme = {
  global: {
    colors: {
      brand: {
        dark: '#4d4545',
        light: '#272c2c',
      },
      // цвет кнопки авторизации
      buttonAuth: {
        dark: '#FCFCFD',
        light: '#272c2c',
      },
      // цвет footer и header
      baseBackground: {
        dark: '#4d4545',
        light: '#FCFCFD',
      },
      // основной цвет background
      background: {
        dark: '#2B2B2B',
        light: '#F1F1F1',
      },
      borderElement: {
        dark: 'rgba(227,227,227,0.45)',
        light: 'rgba(134, 134, 134, 0.45)',
      },
      // цвет background SideBar
      backgroundSideBar: {
        dark: '#5b5858',
        light: '#42388f',
      },
      // цвет background Authorization page
      backgroundForm: {
        dark: '#5b5858',
        light: '#ffffff',
      },
      text: {
        dark: '#EEEEEE',
        light: '#333333',
      },
      // цвет фокуса активных элементов
      focus: {
        dark: '#FCFCFD',
        light: '#272c2c',
      },
      deleteOrError: {
        dark: '#ff5858',
        light: '#ff5858',
      },
      headerTable: {
        dark: '#756868',
        light: '#ffffff',
      },
    },
    font: {
      family: `-apple-system,
         BlinkMacSystemFont, 
         "Segoe UI", 
         Roboto, 
         Oxygen, 
         Ubuntu, 
         Cantarell, 
         "Fira Sans", 
         "Droid Sans",  
         "Helvetica Neue", 
         Arial, sans-serif,  
         "Apple Color Emoji", 
         "Segoe UI Emoji", 
         "Segoe UI Symbol"`,
    },
  },
  button: {
    primary: {
      color: { dark: 'white', light: 'black' },
    },
    border: { color: { dark: 'white', light: 'black' }, width: '2px' },
  },
  select: {
    icons: {
      color: {
        dark: '#EEEEEE',
        light: '#333333',
      },
    },
  },
  accordion: {
    icons: {
      color: {
        dark: '#EEEEEE',
        light: '#333333',
      },
    },
  },
};
