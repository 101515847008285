import React, { useState } from 'react';
import { getUsers } from 'api/user';
import { Box, Heading, Main, Button } from 'grommet';
import { TUsers } from 'models/Users';
import { TableComponent } from './Component/TableComponent';
import { LinkButton } from './Component/LinkButton';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';
import { AccordionComponent } from './Component/Accordion';
import { themeStore } from 'store/ThemeStore';
import { Loader } from 'components/Loader';

export const UsersTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState<TUsers>();
  const [pageCount, setPageCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const take = Number(searchParams.get('take'));
  const skip = Number(searchParams.get('skip'));
  const sortColumn = String(searchParams.get('sort'));
  const sortOrder = String(searchParams.get('order'));
  let currentPage = 0;
  if (take && skip) {
    currentPage = skip / take;
  }

  const handleGetUsers = async () => {
    setIsLoading(true);
    getUsers(String(take), String(skip), sortColumn, sortOrder)
      .then((result) => {
        setCurrentItems(result);
        setPageCount(Math.ceil(result.count / take));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    handleGetUsers();
  }, [searchParams]);

  const handlePageClick = (event: { selected: number }) => {
    if (currentItems?.count) {
      const newSkip = (event.selected * take) % currentItems.count;
      const newParams = {
        take: String(take),
        skip: String(newSkip),
        sort: sortColumn,
        order: sortOrder,
      };
      setSearchParams(newParams);
    }
  };
  const pageLinkClass = themeStore.themeMode === 'light' ? 'page-link-light' : 'page-link-dark';
  return (
    <Main pad='medium' overflow='hidden'>
      <Heading level='3'>Пользователи</Heading>
      {pageCount > 0 && sortColumn && sortOrder && (
        <>
          <Box>
            <LinkButton
              to={'/users/new'}
              state={{ backURL: `${location.pathname}${location.search}` }}
            >
              <Button primary type='button' label='Новый пользователь' color='focus' />
            </LinkButton>
          </Box>
          <AccordionComponent
            take={String(take)}
            setSearchParams={setSearchParams}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
          />

          <TableComponent
            currentItems={currentItems?.items}
            isLoading={isLoading}
            setSearchParams={setSearchParams}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            take={String(take)}
            skip={String(skip)}
            handleGetUsers={handleGetUsers}
          />
          <ReactPaginate
            breakLabel='...'
            nextLabel={currentPage === pageCount - 1 ? null : '>'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : '<'}
            forcePage={currentPage}
            renderOnZeroPageCount={() => null}
            hrefBuilder={(pageIndex: number) => {
              return `/users?take=${take}&skip=${String((pageIndex - 1) * Number(take))}`;
            }}
            containerClassName='pagination'
            pageClassName='page-item'
            pageLinkClassName={pageLinkClass}
            previousClassName='page-item'
            previousLinkClassName={pageLinkClass}
            nextClassName='page-item'
            nextLinkClassName={pageLinkClass}
            breakClassName='page-item'
            breakLinkClassName={pageLinkClass}
            activeLinkClassName='active-page'
          />
        </>
      )}
      {isLoading && currentItems === undefined && <Loader fill={true} />}
    </Main>
  );
};
