import React, { FC, useState } from 'react';
import {
  Form,
  FormField as Field,
  Button,
  Box,
  TextInput,
  MaskedInput,
  Text,
  Spinner,
} from 'grommet';
import { User, Key } from 'grommet-icons';
import { HeaderComponent } from './component/Header';
import styled from 'styled-components';
import { LogoComponent } from 'assets/img/LogoComponent';
import { rootStore } from 'store/RootStore';
import { TAuthRequest } from 'models/Auth';
import { getAuthenticationOptions, postAuthenticationVerification } from 'api/auth';
import { startAuthentication } from '@simplewebauthn/browser';

export const FormBox = styled(Box)`
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1);
  min-width: 384px;
  margin: 20px;
  padding: 20px;
`;
export const WrapperBox = styled(Box)`
  margin-bottom: 48px;
  height: 100%;
  min-height: 352px;
`;
const LogoBox = styled(Box)`
  margin: 20px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonAuth = styled(Button)`
  height: 38px;
  width: 95px;
  margin-top: 5px;
`;
const BoxAuth = styled(Box)`
  min-height: 40px;
`;

const ButtonPasskey = styled(Button)`
  margin-top: 10px;
`;

export const LoginComponent: FC = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleSubmitLoginForm = (formData: TAuthRequest): void => {
    setLoadingLogin(true);
    rootStore.AuthStore.login(formData).then(() => setLoadingLogin(false));
  };

  const handleSubmitLoginFormPasskey = async () => {
    try {
      const options = await getAuthenticationOptions();

      const credentials = await startAuthentication(options);

      const user = await postAuthenticationVerification(credentials);

      rootStore.AuthStore.setCurrentUserData(user);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const errorAuth = rootStore.AuthStore.errorCurrentUser;

  return (
    <div className='main-layout'>
      <HeaderComponent />
      <WrapperBox fill align='center' justify='center'>
        <Text size='xlarge'>Admin panel</Text>
        <FormBox width='medium' background='backgroundForm'>
          <Form
            name='basic'
            onSubmit={(event) => {
              handleSubmitLoginForm(event.value as TAuthRequest);
            }}
            className='form'
          >
            <Field label='Login' name='login' className='login-form__input'>
              <MaskedInput
                type='text'
                name='login'
                autoComplete="login webauthn"
                required
                reverse
                icon={<User />}
              />
            </Field>
            <Field label='Password' name='password' className='login-form__input'>
              <TextInput reverse name='password' type='password' icon={<Key />} required />
            </Field>
            <Box direction='column' align={'center'}>
              <BoxAuth>
                {errorAuth && (
                  <Text
                    color='deleteOrError'
                    alignSelf='center'
                    textAlign={'center'}
                    margin={'xsmall'}
                  >
                    {errorAuth}
                  </Text>
                )}
              </BoxAuth>
              <ButtonAuth
                primary
                color='buttonAuth'
                type='submit'
                justify='center'
                disabled={loadingLogin}
                label={
                  loadingLogin ? (
                    <Box direction='row' gap='small' justify={'center'}>
                      <Spinner color={'background'} />
                    </Box>
                  ) : (
                    'Войти'
                  )
                }
              />
            </Box>
          </Form>
        </FormBox>
        <ButtonPasskey onClick={handleSubmitLoginFormPasskey}>
          Беспарольный вход
        </ButtonPasskey>
      </WrapperBox>
      <LogoBox>
        <LogoComponent isChangeColor={true} />
      </LogoBox>
    </div>
  );
};
