import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Grommet } from 'grommet';
import { theme } from './Theme';
import { RouteSwitch } from './RouteSwitch';
import { themeStore } from './store/ThemeStore';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const GrommetStyled = styled(Grommet)`
  overflow-x: hidden;
  // Чтобы скролл не сдвигал страницу
  margin-right: calc(100vw - 100%);
  margin-left: 0;
`;

const App = observer(() => {
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        draggable={false}
        theme={themeStore.themeMode}
        newestOnTop={true}
      />
      <GrommetStyled full theme={theme} themeMode={themeStore.themeMode}>
        <RouteSwitch />
      </GrommetStyled>
    </BrowserRouter>
  );
});

export default App;
