import { themeStore } from '../store/ThemeStore';
import { theme } from '../Theme';

type ColorTheme =
  | 'brand'
  | 'buttonAuth'
  | 'baseBackground'
  | 'background'
  | 'borderElement'
  | 'backgroundSideBar'
  | 'backgroundForm'
  | 'text'
  | 'focus'
  | 'deleteOrError'
  | 'headerTable';

export const getColorTheme = (colorNameTheme: ColorTheme) => {
  if (themeStore.themeMode === 'dark') {
    return theme.global.colors[colorNameTheme].dark;
  } else {
    return theme.global.colors[colorNameTheme].light;
  }
};
