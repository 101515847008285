import React, { useEffect, useState } from 'react';
import { Button, Heading, Main, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Trash as IconTrash } from 'grommet-icons/icons';
import { startRegistration } from '@simplewebauthn/browser';
import { getRegistrationOptions, postRegistrationVerification } from 'api/auth';
import { rootStore } from 'store/RootStore';
import { TAuthenticator } from 'models/Authenticator.tx';


const ButtonPasskey = styled(Button)`
  height: 38px;
  width: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
`;

export const UserSettingLib = observer(() => {
  // Проверяем, поддерживается ли авторизация по passkey
  const [isAuthPasskey, setIsAuthPassKey] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    rootStore.AuthStore.requestAuthenticators();
  }, []);

  const createNewPasskey = async () => {
    try {
      const options = await getRegistrationOptions();

      const name = prompt('Как назвать ключ?');

      const credentials = await startRegistration(options);

      const isVerified = await postRegistrationVerification(credentials, name || '');

      if (isVerified) rootStore.AuthStore.requestAuthenticators();
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  };

  const checkPasskey = () => {
    // Проверяем есть ли на устройстве локальный аутентификатор и будет ли браузер поддерживать пароли
    if (
      !window.PublicKeyCredential ||
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      !(PublicKeyCredential as any).isConditionalMediationAvailable
    ) {
      setIsAuthPassKey(false);
      return;
    }
    Promise.all([
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (PublicKeyCredential as any).isConditionalMediationAvailable(),
      // Статический метод, возвращающий Promise.
      // Разрешается в true если есть аутентификатор, привязанный к платформе, способен проверить пользователя.
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
    ]).then((values) => {
      if (values.every((x) => x === true)) {
        setIsAuthPassKey(true);
        createNewPasskey();
      }
    });
  };

  const handleDelete = (authenticator: TAuthenticator) => () => {
    const isOk = confirm(`Удалить ключ ${authenticator.name} от ${authenticator.createdAt} ?`);
    if (!isOk) return;
    rootStore.AuthStore.removeAuthenticator(authenticator.id);
  }

  return (
    <Main pad='medium' overflow='hidden'>
      <Heading level='3'>Настройка пользователя</Heading>

      <Text>
        Использовать беспарольную авторизацию (passkey)
        <ButtonPasskey
          primary
          type='button'
          color='focus'
          label='Создать passkey'
          onClick={checkPasskey}
        ></ButtonPasskey>
      </Text>
      {!isAuthPasskey && isAuthPasskey !== undefined && (
        <Text weight='bold'>
          Беспарольная авторизация (passkey) на вашем устройстве не доступна! Беспарольную
          авторизацию можно использовать только на устройствах, поддерживающих аутентификатор
          платформы (например, по отпечатку пальцев).
        </Text>
      )}

      {!!rootStore.AuthStore.authenticators.length && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                Название ключа
              </TableCell>
              <TableCell scope="col" border="bottom">
                Тип
              </TableCell>
              <TableCell scope="col" border="bottom">
                Дата создания
              </TableCell>
              <TableCell scope="col" border="bottom" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {rootStore.AuthStore.authenticators.map((authenticator) => (
              <TableRow key={authenticator.id}>
                <TableCell scope="row">
                  <strong>{authenticator.name}</strong>
                </TableCell>
                <TableCell>{authenticator.credentialDeviceType}</TableCell>
                <TableCell>{authenticator.createdAt}</TableCell>
                <TableCell>
                  <Button
                    icon={<IconTrash color='background' />}
                    onClick={handleDelete(authenticator)}
                    color={'deleteOrError'}
                    primary
                    tip={{
                      content: 'Удалить',
                      plain: true,
                    }}
                    plain={false}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Main>
  );
});
