import React from 'react';
import { Avatar, Box, Text } from 'grommet';
import { MainSideBarButton } from './MainSideBarButton';
import { HomeRounded as IconHome, User } from 'grommet-icons';
import { LogoComponent } from '../../assets/img/LogoComponent';
import styled from 'styled-components';
import { INITIAL_FILTER_PARAMS_API } from '../../constants/globalConstants';
import { rootStore } from '../../store/RootStore';

export const LogoBox = styled(Box)`
  position: absolute;
  bottom: 20px;
  padding: 10px 35px;
`;
export const MainSideBarBox = styled(Box)`
  width: 210px;
`;

export function MainSideBar() {
  return (
    <MainSideBarBox
      gridArea='sidebar'
      background='backgroundSideBar'
      animation={[
        { type: 'fadeIn', duration: 300 },
        { type: 'slideRight', size: 'medium', duration: 150 },
      ]}
    >
      <Box direction='row' pad={{ horizontal: 'medium', vertical: 'small' }}>
        <Avatar background='baseBackground' src='no-avatar-male.gif' />
        <Text margin='small' weight='bold'>
          {rootStore.AuthStore.currentUserData?.login}
        </Text>
      </Box>
      <MainSideBarButton icon={<IconHome />} name={'Главная'} link={'/'} />
      <MainSideBarButton icon={<User />} name={'Пользователи'} link={INITIAL_FILTER_PARAMS_API} />
      <LogoBox direction='row' align='center' gap='small'>
        <LogoComponent isChangeColor={false} />
      </LogoBox>
    </MainSideBarBox>
  );
}
