import React, { FC } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { LayoutForInnerRoute } from './layouts/LayoutForInnerRoute';
import { Page404 } from './containers/Page404/Page404';
import { UsersTable } from './components/Main/Components/Users/UserTable/UsersTable';
import { UserForm } from './components/Main/Components/Users/UserForm/UserForm';
import { UserSettingLib } from './components/Main/Components/UserSettingLib';

export const AllRoutesRender: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<LayoutForInnerRoute />}>
        <Route path='/users' element={<Outlet />}>
          <Route path='new' element={<UserForm />} />
          <Route path='edit/:id' element={<UserForm />} />
          <Route index element={<UsersTable />} />
        </Route>
        <Route path='/user-settings' element={<UserSettingLib />} />
        <Route path='*' element={<Page404 />} />
      </Route>
    </Routes>
  );
};
