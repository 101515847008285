import { Footer, Text } from 'grommet';
import React from 'react';

export const FooterComponent = () => {
  return (
    <Footer
      gridArea='footer'
      background='baseBackground'
      pad='small'
      justify='center'
      border={{
        color: 'borderElement',
        side: 'top',
        size: '1px',
        style: 'outset',
      }}
    >
      <Text textAlign='center' size='small'>
        © 2022 Freematiq
      </Text>
    </Footer>
  );
};
