import { TCurrentUser } from 'models/CurrentUser';
import { Box, Button, DataTable, Text } from 'grommet';
import { Edit as IconEdit, Trash as IconTrash } from 'grommet-icons';
import React, { useRef, useState } from 'react';
import { deleteUser } from 'api/user';
import { Loader } from 'components/Loader';
import { rootStore } from 'store/RootStore';
import { LinkButtonEdit } from './LinkButton';
import { useApiUserActions } from 'api/hooks/useApiUserActions';
import styled from 'styled-components';
import { callIteractivePopap } from 'common/InteractivePopap';
import { INITIAL_FILTER_PARAMS_API, TABLE_USER_SORT_VALUE } from 'constants/globalConstants';
import { URLSearchParamsInit, useLocation } from 'react-router-dom';
import { getColorTheme } from 'utils/getColorTheme';

const ButtonDelete = styled(Button)`
  margin-left: 10px;
`;

type Props = {
  currentItems: TCurrentUser[] | undefined;
  isLoading: boolean;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: { replace?: boolean | undefined; state?: never } | undefined,
  ) => void;
  sortColumn: string;
  sortOrder: string;
  take: string;
  skip: string;
  handleGetUsers: () => Promise<void>;
};

const columns = [
  {
    property: 'id',
    header: <Text>{TABLE_USER_SORT_VALUE.id}</Text>,
    primary: true,
  },
  {
    property: 'name',
    header: <Text>{TABLE_USER_SORT_VALUE.name}</Text>,
  },
  {
    property: 'login',
    header: <Text>{TABLE_USER_SORT_VALUE.login}</Text>,
  },
  {
    property: 'role',
    header: <Text>{TABLE_USER_SORT_VALUE.role}</Text>,
  },
  {
    property: 'createdAt',
    header: <Text>{TABLE_USER_SORT_VALUE.createdAt}</Text>,
  },
  {
    property: 'updatedAt',
    header: <Text>{TABLE_USER_SORT_VALUE.updatedAt}</Text>,
  },
  {
    property: 'actions',
    header: <Text>Действия</Text>,
    sortable: false,
  },
];

export const TableComponent = ({
  currentItems,
  isLoading,
  setSearchParams,
  sortOrder,
  sortColumn,
  take,
  skip,
  handleGetUsers,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const location = useLocation();

  const { isLoadingHook, queryData, resultHook } = useApiUserActions();

  const onChangeFilter = (sortColumnValue: string, sortOrderValue: string) => {
    const newParams = {
      take: take,
      skip: skip,
      sort: sortColumnValue,
      order: sortOrderValue,
    };
    setSearchParams(newParams);
  };

  const dataForDataTable = currentItems?.map((item) => {
    const actionItem = (
      <Box flex direction='row' alignSelf={'start'} justify={'start'}>
        <LinkButtonEdit
          to={`/users/edit/${item.id}`}
          state={{ backURL: `${location.pathname}${location.search}` }}
        >
          <Button
            icon={<IconEdit color='background' />}
            disabled={isLoadingHook && isLoading}
            color={'focus'}
            primary
            tip={{
              content: `Редактировать ${item.login}`,
              plain: true,
            }}
            plain={false}
          ></Button>
        </LinkButtonEdit>
        {item.id !== rootStore.AuthStore.currentUserData?.id && (
          <ButtonDelete
            icon={<IconTrash color='background' />}
            onClick={() => deleteHandler(item.id, item.login)}
            disabled={isLoadingHook && isLoading}
            color={'deleteOrError'}
            primary
            tip={{
              content: `Удалить ${item.login}`,
              plain: true,
            }}
            plain={false}
          ></ButtonDelete>
        )}
      </Box>
    );
    return { ...item, actions: actionItem };
  });

  const deleteHandler = (idUser: string, loginUser: string) => {
    const getURL = () => {
      if (currentItems && currentItems.length > 1) {
        return `${location.pathname}${location.search}`;
      } else {
        if (Number(skip) > 0) {
          return (
            `${location.pathname}?take=${take}&skip=${Number(skip) - Number(take)}` +
            `&sort=${sortColumn}&order=${sortOrder}`
          );
        }
        return INITIAL_FILTER_PARAMS_API;
      }
    };

    const cbSuccess = () => {
      const messageSuccess = 'Пользователь успешно удален';
      const messageError = 'Ошибка при удалении пользователя';
      setIsLoadingDelete(true);
      queryData(deleteUser, messageSuccess, messageError, {}, idUser, getURL());
    };
    callIteractivePopap(`Вы действительно хотите удалить пользователя ${loginUser}?`, cbSuccess);
  };

  React.useEffect(() => {
    const heightTable = ref?.current?.clientHeight;
    if (heightTable) setHeight(heightTable);
  }, [ref?.current?.clientHeight]);

  // При удалении пользователя из таблицы перезапрашиваем данные
  React.useEffect(() => {
    if (resultHook) {
      handleGetUsers().then(() => {
        setIsLoadingDelete(false);
      });
    }
  }, [resultHook]);

  return (
    <Box ref={ref} overflow={{ horizontal: 'auto', vertical: 'hidden' }}>
      <DataTable
        sort={{
          property: `${sortColumn}`,
          direction: `${sortOrder === 'asc' ? 'asc' : 'desc'}`,
          external: true,
        }}
        columns={columns}
        data={dataForDataTable}
        resizeable={true}
        pad='small'
        onSort={({ property, direction }) => {
          onChangeFilter(property, direction);
        }}
        placeholder={
          (isLoading || isLoadingDelete) && currentItems !== undefined ? (
            <Box background={{ color: 'backgroundForm', opacity: 'medium' }} fill>
              <Loader height={height} fill={false} />{' '}
            </Box>
          ) : (
            ''
          )
        }
        background={{
          header: getColorTheme('headerTable'),
          body: ['background', 'baseBackground'],
          footer: 'backgroundSideBar',
        }}
        border={{ body: 'bottom' }}
        verticalAlign={{
          header: 'middle',
          body: 'middle',
          footer: 'middle',
        }}
        margin={'small'}
      />
    </Box>
  );
};
