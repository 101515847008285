import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/typescript-types';
import { TAuthRequest, TAuthResponse, TAuthResponseError, TLogoutResponse } from 'models/Auth';
import { api } from 'api/client';
import { TCurrentUser } from '../models/CurrentUser';

export const postAuth = async (data: TAuthRequest): Promise<TAuthResponse | TAuthResponseError> => {
  return await api.send('POST', '/auth/login', data);
};

export const postLogout = async (): Promise<TLogoutResponse> => {
  return await api.send('POST', '/auth/logout');
};

export const getRegistrationOptions = (): Promise<PublicKeyCredentialCreationOptionsJSON> => {
  return api.send('GET', '/auth/generate-registration-options');
};

export const postRegistrationVerification = (data: RegistrationResponseJSON, name: string): Promise<boolean> => {
  return api.send('POST', '/auth/verify-registration', { ...data, name });
};

export const getAuthenticationOptions = (): Promise<PublicKeyCredentialRequestOptionsJSON> => {
  return api.send('GET', '/auth/generate-authentication-options');
};

export const postAuthenticationVerification = (
  data: AuthenticationResponseJSON,
): Promise<TCurrentUser> => {
  return api.send('POST', '/auth/verify-authentication', data);
};
