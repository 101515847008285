import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { INewUser } from '../../models/Users';
import { TCurrentUser } from '../../models/CurrentUser';

export const useApiUserActions = () => {
  const [isLoadingHook, setIsLoadingHook] = React.useState<boolean>(false);
  const [resultHook, setResultHook] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const currentURL = `${location.pathname}${location.search}`;

  const queryData = (
    fnApi: (
      data: INewUser | Record<string, unknown>,
      idUser: string | undefined,
    ) => Promise<TCurrentUser>,
    messageSuccess: string,
    messageError: string,
    data: INewUser | Record<string, unknown>,
    id: string | undefined,
    backURL: string,
  ): void => {
    setIsLoadingHook(true);
    setResultHook(false);
    fnApi(data, id)
      .then((result) => {
        if (result) {
          if (backURL !== currentURL) {
            navigate(backURL);
          }
          setResultHook(true);
          toast.success(messageSuccess);
        }
      })
      .catch(() => {
        toast.error(messageError);
      })
      .finally(() => {
        setIsLoadingHook(false);
      });
  };

  return { queryData, isLoadingHook, resultHook };
};
