import React from 'react';
import styled from 'styled-components';
import { Id, toast } from 'react-toastify';
import { getColorTheme } from '../utils/getColorTheme';
import { Box, Text } from 'grommet';

export const callIteractivePopap = (message: string, cbSuccess: () => void) => {
  const ButtonPopap = styled.button`
    background-color: ${getColorTheme('text')};
    color: ${getColorTheme('background')};
    font-size: 1em;
    margin: 1em 0.5em;
    padding: 0.25em 1.5em;
    border-radius: 18px;
    line-height: 24px;
    border: none;
    outline: none;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 2px ${getColorTheme('text')};
      transition-property: color, background-color, border-color, box-shadow;
      transition-timing-function: ease-in-out;
      transition-duration: 0.1s;
    }
  `;

  const TextPopap = styled(Text)`
    word-break: break-word;
  `;
  const toastId: Id = toast.info(
    <>
      <TextPopap size={'small'}>{message}</TextPopap>
      <Box direction='row' justify='center'>
        <ButtonPopap
          onClick={() => {
            cbSuccess();
            toast.dismiss(toastId);
          }}
        >
          Ок
        </ButtonPopap>
        <ButtonPopap onClick={() => toast.dismiss(toastId)}>Отмена</ButtonPopap>
      </Box>
    </>,
    { autoClose: false, closeOnClick: false, className: 'toastify-no-pointer' },
  );
  return <div></div>;
};
