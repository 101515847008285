import { TCurrentUser } from './CurrentUser';

export type TUsers = {
  items: TCurrentUser[];
  count: number;
};

export enum UserRole {
  User = 'user',
  Admin = 'admin',
}

export interface INewUser {
  name: string;
  login: string;
  password?: string;
  role: UserRole;
}

export type TableSortValueType = 'id' | 'createdAt' | 'updatedAt' | 'name' | 'login' | 'role';

export type TableOrderValueType = 'asc' | 'desc';
