import { routes } from 'routes';
import { PREFIX_API } from '../constants/globalConstants';
import { toast } from 'react-toastify';

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

class ApiClient {
  url: string;

  constructor(url = '') {
    this.url = url;
  }

  private static setHeaders(options: Record<string, string> = {}): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      ...options,
    };
  }

  private static setBody(data?: object) {
    if (data instanceof FormData) {
      return data;
    }
    return typeof data === 'object' ? JSON.stringify(data) : undefined;
  }

  public async send<T>(
    method: MethodType,
    path: string,
    data?: object,
    headers?: Record<string, string>,
  ): Promise<T> {
    try {
      const response = await fetch(`${this.url}${PREFIX_API}${path}`, {
        method,
        body: ApiClient.setBody(data),
        headers: ApiClient.setHeaders({
          ...headers,
        }),
        credentials: 'include',
      });

      // Обработчик для метода logout
      if (path === '/auth/logout') {
        if (response.ok) {
          return <T>{ isLogout: true };
        } else {
          return <T>{ isLogout: false };
        }
      }

      const result = await response.json();

      if (response.status === 401) {
        const message = this.getMessageError(result.message);
        console.error(message);
        const pathname = window.location.pathname;
        const isOuterRoute = routes
          .filter((route) => route.isOuter)
          .findIndex((route) => route.path === pathname);
        if (isOuterRoute === -1) {
          window.location.replace('/login');
        }
        return Promise.reject(result);
      }

      if (!response.ok && path !== '/auth/login') {
        const message = this.getMessageError(result.message);
        toast.error(message);
        return Promise.reject(result);
      }

      return result;
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  }

  private getMessageError = (message: string | string[]) => {
    if (typeof message === 'string') {
      return message;
    }
    if (Array.isArray(message)) {
      return message.join(', ');
    }
    return 'Something went wrong...';
  };
}

export const api = new ApiClient(process.env.REACT_APP_API_BASE || '');
