import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid } from 'grommet';
import { MainSideBar } from '../SideBar/MainSideBar';
import styled from 'styled-components';
import { Loader } from '../Loader';
import { HeaderComponent } from '../Header/Header';
import { FooterComponent } from '../Footer/Footer';
import { rootStore } from '../../store/RootStore';

const WrapperGrid = styled(Grid)`
  // чтобы footer всегда был в низу
  min-height: 100%;
  width: 100vw;
`;

type Props = {
  children: React.ReactNode;
};

export const Main = observer(({ children }: Props) => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <WrapperGrid
      rows={['auto', 'flex', 'auto']}
      columns={['auto', 'flex']}
      areas={[
        ['sidebar', 'header'],
        ['sidebar', 'main'],
        ['sidebar', 'footer'],
      ]}
    >
      <HeaderComponent sidebar={sidebar} setSidebar={setSidebar} />
      {sidebar && <MainSideBar />}
      {rootStore.AuthStore.isLoading ? (
        <Box gridArea='main' justify='center' align='center'>
          <Loader fill={false} />{' '}
        </Box>
      ) : (
        <Box gridArea='main'>{children}</Box>
      )}
      <FooterComponent />
    </WrapperGrid>
  );
});
