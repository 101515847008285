import { TCurrentUser } from 'models/CurrentUser';
import { api } from 'api/client';
import { INewUser, TUsers } from '../models/Users';
import { TAuthenticator } from '../models/Authenticator.tx';

export const getCurrentUser = async (): Promise<TCurrentUser> => {
  return await api.send('GET', '/users/current');
};

export const getUsers = async (
  take: string,
  skip: string,
  sortColumn: string,
  sortOrder: string,
): Promise<TUsers> => {
  return await api.send(
    'GET',
    `/users?skip=${skip}&take=${take}&sort=${sortColumn}&order=${sortOrder}`,
  );
};

export const getUser = async (id: string): Promise<TCurrentUser> => {
  return await api.send('GET', `/users/${id}`);
};

export const sendUserData = async (
  data: INewUser | Record<string, unknown>,
  idUser?: string | undefined,
): Promise<TCurrentUser> => {
  return await api.send(idUser ? 'PATCH' : 'POST', idUser ? `/users/${idUser}` : '/users', data);
};

export const deleteUser = async (
  data: INewUser | Record<string, unknown>,
  idUser: string | undefined,
): Promise<TCurrentUser> => {
  return await api.send('DELETE', `/users/${idUser}`);
};

export const getCurrentUserAuthenticators = (): Promise<TAuthenticator[]> =>
  api.send('GET', '/users/current/authenticators');

export const removeCurrentUserAuthenticator = async (authenticatorId: string | number): Promise<boolean> =>
  api.send('DELETE', `/users/current/authenticators/${authenticatorId}`);
