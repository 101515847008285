import React, { FC } from 'react';
import { themeStore } from '../store/ThemeStore';
import { Button } from 'grommet';
import { Moon, Sun } from 'grommet-icons';
import { observer } from 'mobx-react-lite';

export const ThemeSwitchButton: FC = observer(() => {
  return themeStore.themeMode == 'light' ? (
    <Button icon={<Moon />} onClick={() => themeStore.setDark()} />
  ) : (
    <Button icon={<Sun />} onClick={() => themeStore.setLight()} />
  );
});
