import React from 'react';
import { Box, Text } from 'grommet';
import { ThemeSwitchButton } from '../../../ThemeSwitch/ThemeSwitchButton';
import styled from 'styled-components';

const HeaderBox = styled(Box)`
  margin-right: 10px;
`;

export function HeaderComponent() {
  return (
    <header>
      <HeaderBox direction='row' align='center' justify='end'>
        <Text size='small'>Theme:</Text>
        <ThemeSwitchButton />
      </HeaderBox>
    </header>
  );
}
