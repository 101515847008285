export const PREFIX_API = '/api';
export const INITIAL_FILTER = {
  take: 5,
  skip: 0,
  sort: 'name',
  order: 'asc',
};

export const INITIAL_FILTER_PARAMS_API =
  `/users?take=${INITIAL_FILTER.take}&skip=${INITIAL_FILTER.skip}` +
  `&sort=${INITIAL_FILTER.sort}&order=${INITIAL_FILTER.order}`;

export const FILTER_SHOW_BY = ['2', '5', '10', '20'];

export const TABLE_USER_SORT_VALUE = {
  id: 'ИД',
  createdAt: 'Создан',
  updatedAt: 'Обновлен',
  name: 'Имя пользователя',
  login: 'Логин',
  role: 'Роль',
};

export const TABLE_USER_ORDER_VALUE = {
  asc: 'По возрастанию',
  desc: 'По убыванию',
};
