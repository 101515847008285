import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField as Field,
  Heading,
  Main,
  Select,
  Spinner,
  TextInput,
} from 'grommet';
import { useLocation, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { LinkButton } from '../UserTable/Component/LinkButton';
import { INewUser, UserRole } from 'models/Users';
import { getUser, sendUserData } from 'api/user';
import { rootStore } from 'store/RootStore';
import styled from 'styled-components';
import { Loader } from 'components/Loader';
import { INITIAL_FILTER_PARAMS_API } from 'constants/globalConstants';
import { useApiUserActions } from 'api/hooks/useApiUserActions';

const FormField = styled(Field)`
  margin: 0 10px 10px 10px;
`;

export const FormBox = styled(Box)`
  border-radius: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 300px;
`;

export const ButtonBox = styled(Box)`
  margin: 30px 0 20px 0;
`;

type ParamTypes = Partial<Record<'id', string>>;

interface FormState extends INewUser {
  confirmPassword: '';
}

const initialFormState: FormState = {
  name: '',
  login: '',
  password: '',
  confirmPassword: '',
  role: UserRole.User,
};

const ButtonAction = styled(Button)`
  height: 38px;
  width: 140px;
  margin-top: 5px;
`;

export const UserForm = () => {
  const [valueForm, setValueForm] = useState<FormState>(initialFormState);
  const { id } = useParams<ParamTypes>();
  const [isLoading, setIsLoading] = useState(!!id);

  const location = useLocation();
  let backURL = INITIAL_FILTER_PARAMS_API;
  if (location.state && 'backURL' in location.state) {
    backURL = location.state.backURL;
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getUser(id)
        .then((result) => {
          setValueForm({
            name: result.name,
            login: result.login,
            password: '',
            confirmPassword: '',
            role: result.role,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const { isLoadingHook, queryData } = useApiUserActions();

  const handlerSubmitUserForm = (formValue: FormState) => {
    const data: INewUser = {
      name: formValue.name,
      login: formValue.login,
      role: formValue.role,
    };
    if (formValue.password !== '' && formValue.confirmPassword !== '') {
      data.password = formValue.password;
    }
    const messageSuccess = id
      ? 'Пользователь успешно отредактирован'
      : 'Пользователь успешно создан';
    const messageError = id
      ? 'Ошибка при редактировании пользователя'
      : 'Ошибка при создании пользователя';
    queryData(sendUserData, messageSuccess, messageError, data, id, backURL);
  };

  const validatePassword = (password: string, confirmPassword: string) => {
    if (password === '' && confirmPassword === '' && !id)
      return 'Создать нового пользователя без пароля нельзя';
    if (password.length > 0 && password.length < 6) return 'Пароль должен быть минимум 6 символов';
    if (password !== confirmPassword) return 'Пароли не совпадают';
    return undefined;
  };

  return (
    <Main pad='medium' overflow='hidden'>
      <Heading level='3'>
        {id ? `Редактирование пользователя ${valueForm.login}` : 'Создание нового пользователя'}
      </Heading>
      <Box fill align='start' justify='start'>
        {isLoading && <Loader fill={true} />}
        {!isLoading && (
          <FormBox width='large' background={'backgroundForm'}>
            <Form
              value={valueForm}
              validate='submit'
              onChange={(nextValue) => setValueForm(nextValue)}
              onSubmit={({ value }) => handlerSubmitUserForm(value)}
            >
              <FormField name='name' htmlFor='text-input-name' label='Имя пользователя'>
                <TextInput id='text-input-name' name='name' />
              </FormField>
              <FormField name='login' htmlFor='text-input-login' label='Логин'>
                <TextInput id='text-input-login' name='login' required />
              </FormField>
              <FormField name='role' htmlFor='text-select-role' label='Роль'>
                {id !== String(rootStore.AuthStore.currentUserData?.id) && (
                  <Select options={['admin', 'user']} id='text-select-role' name='role' />
                )}
                {id === String(rootStore.AuthStore.currentUserData?.id) && (
                  <TextInput id='role' name='role' value={valueForm.role} readOnly />
                )}
              </FormField>
              <FormField
                name='password'
                htmlFor='text-input-password'
                label={id ? 'Новый пароль' : 'Пароль'}
                validate={[
                  (password, formValue) =>
                    validatePassword(password as string, formValue.confirmPassword as string),
                ]}
              >
                <TextInput id='text-input-password' name='password' />
              </FormField>
              <FormField
                name='confirmPassword'
                htmlFor='text-input-confirm-password'
                label='Подтверждение пароля'
                validate={[
                  (confirmPassword, formValue) =>
                    validatePassword(formValue.password as string, confirmPassword as string),
                ]}
              >
                <TextInput id='text-input-confirm-password' name='confirmPassword' />
              </FormField>
              <ButtonBox direction='row' gap='medium' className='product-form-aria-button'>
                <ButtonAction
                  type='submit'
                  primary
                  disabled={isLoadingHook}
                  label={
                    isLoadingHook ? (
                      <Box direction='row' gap='small' justify={'center'}>
                        <Spinner color={'background'} />
                      </Box>
                    ) : id ? (
                      'Сохранить'
                    ) : (
                      'Создать'
                    )
                  }
                />
                <LinkButton to={backURL}>
                  <ButtonAction type='button' label='Отмена' color='focus' />
                </LinkButton>
              </ButtonBox>
            </Form>
          </FormBox>
        )}
      </Box>
    </Main>
  );
};
