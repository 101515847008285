import React from 'react';
import { Spinner, Box } from 'grommet';

type Props = {
  height?: number;
  fill: boolean;
};

export const Loader = ({ height, fill }: Props) => (
  <Box
    height={height ? `${height}px` : ''}
    fill={fill}
    alignContent='center'
    justify='center'
    align={'center'}
  >
    <Spinner size='large' color='text' />
  </Box>
);
