import React from 'react';
import { Login } from 'containers/Auth/Login';
import { Main } from 'containers/Main/Main';
import { Page404 } from 'containers/Page404/Page404';
import { UsersTable } from './components/Main/Components/Users/UserTable/UsersTable';
import { UserForm } from './components/Main/Components/Users/UserForm/UserForm';
import { UserSettingLib } from './components/Main/Components/UserSettingLib';

export type TRoute = {
  path: string;
  component: React.ComponentType;
  isOuter?: boolean;
};

export type TRoutes = TRoute[];

export const routes: TRoutes = [
  { path: '/', component: Main },
  { path: '/users"', component: UsersTable },
  { path: '/users/new', component: UserForm },
  { path: '/users/edit/:id', component: UserForm },
  { path: '/user-settings', component: UserSettingLib },
  { path: '/login', component: Login, isOuter: true },
  { path: '*', component: Page404 },
];
