import { makeAutoObservable } from 'mobx';
import { AuthStore } from 'store/AuthStore';

class RootStore {
  AuthStore = new AuthStore();

  constructor() {
    makeAutoObservable(this);
  }
}

export const rootStore = new RootStore();
