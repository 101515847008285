import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LoginComponent } from 'components/Auth/Login';
import { rootStore } from 'store/RootStore';

export const Login: FC = observer(() => {
  if (rootStore.AuthStore.currentUserExists) {
    return <Navigate to='/' />;
  }

  return <LoginComponent />;
});
